<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        topBorderClass="topcolor-lightblue"
        ref="grid1"
        title="평가항목별 개선목록"
        tableId="grid1"
        :columnSetting="false"
        :usePaging="false"
        :filtering="false"
        :hideBottom="true"
        :columns="grid1.columns"
        :data="grid1.data"
        :merge="grid1.merge"
        :gridHeight="gridheight"
      >
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='customCol'">
            <component
              :is="imprComponent"
              :col="col"
              :props="props"
              :inputEditable="false"
              :isImmShow="true"
              ibmTaskTypeCd="ITT0000035"
              ibmTaskUnderTypeCd="ITTU000060"
              @imprChange="imprChange"
            />
          </template>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="gridImpr"
        title="항목외 개선 목록"
        :columns="gridImpr.columns"
        :data="gridImpr.data"
        :gridHeight="gridheight2"
        :usePaging="false"
        :filtering="false"
        :columnSetting="false"
        @linkClick="linkClick2"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled && popupParam.selfInspectionResultId" label="개선요청" :showLoading="false"  icon="add" @btnClicked="addImpr" />
            <c-btn v-if="editable && !disabled && popupParam.selfInspectionResultId" label="즉시조치" :showLoading="false"  icon="add" @btnClicked="addiimImpr" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hazop-plan-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',
      }),
    },
  },
  data() {
    return {
      requestImprRow: null,
      imprData: null,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      colorItems: [],
      gridheight: '',
      gridheight2: '',
      grid1: {
        merge: [
          { index: 0, colName: 'itemMstName' },
          { index: 1, colName: 'itemTypeName' },
        ],
        columns: [
          {
            name: 'itemMstName',
            field: 'itemMstName',
            label: '평가항목',
            align: 'left',
            style: 'width: 200px',
            sortable: false,
          },
          {
            name: 'itemTypeName',
            field: 'itemTypeName',
            label: '구분',
            align: 'left',
            style: 'width: 200px',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: 'No.',
            align: 'center',
            style: 'width: 60px',
            sortable: false,
          },
          {
            name: 'itemName',
            field: 'itemName',
            label: '세부항목',
            align: 'left',
            type: 'html',
            sortable: false,
          },
          {
            name: 'selfInspectionResultName',
            field: 'selfInspectionResultName',
            label: '평가',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선번호/개선진행상태',
            align: 'center',
            style: 'width:300px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
      },
      gridImpr: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            label: '조치내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        data: [],
      },
      infoUrl: '',
      list1Url: '',
      impListUrl: '',
      impSaveUrl: '',
      impDeleteUrl: '',
      editable: true,
      data: {
        selfInspectionResultId: '',  // 자체감사 계획 일련번호
        selfInspectionTitle: '',  // 감사명
        selfInspectionYear: '',  // 감사년도
        selfInspectionTypeCd: null,  // 감사종류
        plantCd: null,  // 해당사업장
        remark: '',  // 감사대상 개요
        selfInspectionStartDt: '',  // 감사기간-시작일
        selfInspectionEndDt: '',  // 감사기간-종료일
        selfInspectionStatusCd: '',  // 감사진행상태-계획중,계획완료,감사완료
        selfInspectionResultOpinion: '',  // 감사원소견
        selfInspectionResultTotalOpinion: '',  // 감사 결과 종합의견
        selfInspectionResultCorrect: '',  // 시정 및 권고 요약
        selfInspectionDt: [],
        sysRevision: '',  // 평가항목 개정번호
        chgUserId: '',
        sysApprovalRequestId: '',
        approvalTypeCd: '',
        approvalStatusCd: '',
        approvalStatusName: '',
        isSave: false,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.data.selfInspectionStatusCd != 'SISC000001' && this.data.selfInspectionStatusCd != 'SISC000002'
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  methods: {
    init() {
      this.gridheight2 = (window.innerHeight - 608) + 'px';
      this.gridheight = '400px';
      this.editable = this.$route.meta.editable;
      this.infoUrl = selectConfig.sop.pca.result.info.get.url;
      this.list1Url = selectConfig.sop.pca.item.revs.all;
      this.impListUrl = selectConfig.sop.ibm.improve.others.url;
      
      this.getInfo();
      // code setting
      // this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
      //   this.colorItems = _result
      // });

      this.getList();
      this.getImpList();
    },
    getInfo() {
      this.$http.url = this.infoUrl;
      this.$http.type = 'GET';
      this.$http.param = {selfInspectionResultId: this.popupParam.selfInspectionResultId};
      this.$http.request((_result) => {
        this.data = _result.data;
      },);
    },
    getList() {
      this.$http.url = this.list1Url + '/impr';
      this.$http.type = 'GET';
      this.$http.param = {sysRevision: this.popupParam.sysRevision, selfInspectionResultId: this.popupParam.selfInspectionResultId};
      this.$http.request((_result) => {
        this.grid1.data = _result.data;
      },);
    },
    getImpList() {
      if (this.popupParam.selfInspectionResultId) {
        this.$http.url = this.impListUrl;
        this.$http.param = {
          ibmTaskTypeCd: 'ITT0000035', 
          ibmTaskUnderTypeCd: 'ITTU000055', 
          relationTableKey: this.popupParam.selfInspectionResultId
        };
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.gridImpr.data = _result.data;
        },);
      }
    },
    // 개선창 닫기 후
    imprChange() {
      this.getList();
    },
    linkClick(data, row, num) {
      this.requestImprRow = row
      this.imprData = data;
      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        if (num == '2') {
          this.popupOptions.closeCallback = this.closeImprPopup2;
        } else {
          this.popupOptions.closeCallback = this.closeImprPopup;
        }
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        if (num == '2') {
          this.popupOptions.closeCallback = this.closeImprPopup2;
        } else {
          this.popupOptions.closeCallback = this.closeImprPopup;
        }
      }
    },
    linkClick2(row) {
      this.linkClick(row, row, '2');
    },
    closeImprPopup2() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpList();
    },
    closeImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          let sData = this.requestImprRow.sopImprovementIds ? this.requestImprRow.sopImprovementIds : '';
          if (sData.indexOf(item.sopImprovementId) === -1) {
            this.requestImprRow.sopImprovementIds = this.requestImprRow.sopImprovementIds ? 
              this.requestImprRow.sopImprovementIds + ',' + item.sopImprovementId : item.sopImprovementId
            this.requestImprRow.ibmStepNames = this.requestImprRow.ibmStepNames ? 
              this.requestImprRow.ibmStepNames + ',' + item.ibmStepName : item.ibmStepName
          }
        })
        this.$refs['grid1'].$refs['compo-table'].resetVirtualScroll();
        this.research();
      }
    },
    research() {
      this.$emit('research');
    },
    addImpr() {
      this.popupOptions.title = '개선요청';
      this.popupOptions.param = {
        requestContents: this.data.selfInspectionTitle,
        relationTableKey: this.popupParam.selfInspectionResultId,
        ibmTaskTypeCd: 'ITT0000035',
        ibmTaskUnderTypeCd: 'ITTU000055', 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    addiimImpr() {
      this.popupOptions.title = '즉시조치';
      this.popupOptions.param = {
        requestContents: this.data.selfInspectionTitle,
        relationTableKey: this.popupParam.selfInspectionResultId,
        ibmTaskTypeCd: 'ITT0000035',
        ibmTaskUnderTypeCd: 'ITTU000055', 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpList();
    }
  }
};
</script>
